import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import { TAuthFeature } from '@th-common/enums/auth-feature.enum';
import { TAuthMethod } from '@th-common/enums/auth-method.enum';

export interface IAuthState {
  token: string | null;
  refreshToken: string | null;
  expiresIn: number | null;
  authenticationMethod: TAuthMethod;
  isRemember: boolean;
  features: TAuthFeature[];
}

const initialState: IAuthState = {
  token: null,
  refreshToken: null,
  expiresIn: null,
  authenticationMethod: TAuthMethod.Password,
  isRemember: false,
  features: [],
};

export const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    initializeAuth: (state, { payload }) => {
      state.token = payload.token;
      state.refreshToken = payload.refreshToken;
      state.expiresIn = payload.expiresIn; // current time in sec + expiresIn
      state.authenticationMethod = payload.authenticationMethod;
      state.isRemember = payload.isRemember ?? false;
      state.features = payload.features;
    },
    renewAuth: (state, { payload }) => {
      state.token = payload.token;
      state.refreshToken = payload.refreshToken;
      state.expiresIn = payload.expiresIn; // current time in sec + expiresIn
      state.authenticationMethod = payload.authenticationMethod;
      state.isRemember = payload.isRemember ?? false;
      state.features = payload.features;
    },
    logout: () => initialState,
  },
  extraReducers: (builder: ActionReducerMapBuilder<IAuthState>) => {
  },
});

export const { initializeAuth, renewAuth, logout } = slice.actions;
export default slice.reducer;

import {
  ComponentType, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '@th-common/components/common/Loader';
import { StorageAuthKeys } from '@th-common/constants/auth';
import { TAuthFeature } from '@th-common/enums/auth-feature.enum';
import { authStore } from '@th-common/store/auth';
import { StorageUtils } from '@th-common/utils';
import { config } from '@th-common/utils/config';
import { redirect } from 'next/navigation';

import styles from './auth.guard.module.scss';

export function authGuard<P extends object>(Component: ComponentType<P>) {
  return function WithAuth(props: P) {
    const dispatch = useDispatch();

    const [refreshAuthToken, {
      data: refreshTokenResponse,
      error: refreshTokenError,
    }] = authStore.api.useRefreshAuthTokenMutation();

    const isUserAuthenticated = useSelector(authStore.selectors.isUserAuthenticated);
    const refreshToken = useSelector(authStore.selectors.authRefreshToken);
    const tokenExpires = useSelector(authStore.selectors.authTokenExpires);
    const isRemember = useSelector(authStore.selectors.isRemember);

    const LoaderEl = useMemo(() => <div className={styles.wrapper}><Loader /></div>, []);
    const [result, setResult] = useState(LoaderEl);

    useEffect(() => {
      const storageToken = StorageUtils.search<string>(StorageAuthKeys.TOKEN);
      const storageRefreshToken = StorageUtils.search<string>(StorageAuthKeys.REFRESH_TOKEN);
      const storageExpires = Number(StorageUtils.search<string>(StorageAuthKeys.TOKEN_EXPIRES));
      const storageAuthMethod = StorageUtils.search<string>(StorageAuthKeys.AUTH_METHOD);
      const storageIsRemember = StorageUtils.search<boolean>(StorageAuthKeys.IS_REMEMBER);
      const storageFeatures = StorageUtils.search<TAuthFeature[]>(StorageAuthKeys.FEATURES);

      const now = Math.round(Date.now() / 1000);
      const areTokensInStorage = !!(storageToken && storageExpires && storageRefreshToken);

      if (!areTokensInStorage) {
        dispatch(authStore.actions.logout());
        redirect(config.routes.loginPageUrl);
      }

      if (isUserAuthenticated) {
        setResult(<Component {...props} />);
      }

      // if tokens are found and token is not expired but there is no auth in the Redux store
      if (areTokensInStorage && storageExpires > now && !isUserAuthenticated) {
        dispatch(
          authStore.actions.initializeAuth({
            token: storageToken,
            refreshToken: storageRefreshToken,
            expiresIn: storageExpires,
            authenticationMethod: storageAuthMethod,
            isRemember: storageIsRemember,
            features: storageFeatures,
          }),
        );
        setResult(LoaderEl);
      }

      // if tokens are found but token is expired and no auth in the Redux store
      if (areTokensInStorage && storageExpires <= now && !isUserAuthenticated) {
        refreshAuthToken(storageRefreshToken);
        setResult(LoaderEl);
      }
    }, [LoaderEl, dispatch, isUserAuthenticated, props, refreshAuthToken]);

    // set refresh token timeout to refresh the token before it expires
    useEffect(() => {
      const now = Math.round(Date.now() / 1000);
      if (!refreshToken || (tokenExpires && tokenExpires <= now)) {
        return;
      }

      const timeDiff = (((tokenExpires ?? 0) - now) / 2) * 1000; // in ms
      const refreshTokenTimeout = setTimeout(() => {
        refreshAuthToken(refreshToken);
      }, timeDiff);

      return () => clearTimeout(refreshTokenTimeout);
    }, [refreshToken, tokenExpires, refreshAuthToken]);

    // when refresh token is received then initialize the auth
    useEffect(() => {
      if (refreshTokenResponse) {
        dispatch(
          authStore.actions.renewAuth({
            token: refreshTokenResponse.token,
            refreshToken: refreshTokenResponse.refreshToken,
            expiresIn: Math.round(Date.now() / 1000) + refreshTokenResponse.expiresIn,
            authenticationMethod: refreshTokenResponse.authenticationMethod,
            isRemember,
            features: refreshTokenResponse.features,
          }),
        );
      }
    }, [dispatch, isRemember, refreshTokenResponse]);

    // when refresh token is failed then logout
    useEffect(() => {
      if (refreshTokenError) {
        dispatch(authStore.actions.logout());
        redirect(config.routes.loginPageUrl);
      }
    }, [dispatch, refreshTokenError]);

    return result;
  };
}

const passwordMinLength = 3;

const passwordValidationErrorMessages = {
  minLength: `Password must be at least ${passwordMinLength} characters`,
  noSpaces: 'Spaces are not allowed',
  newAndCurrentPasswordMatch: 'New password should not match the current password',
  passwordsDoNotMatch: 'Passwords do not match',
};

const noSpacesValidator = (value: string | undefined | null): boolean => !/\s/.test(`${value}`);

export { noSpacesValidator, passwordMinLength, passwordValidationErrorMessages };

export enum TReduxTag {
  UserProfile = 'UserProfile',
  VideoQueries = 'VideoQueries',
  RidershipValidations = 'RidershipValidations',
  GeographicalQueries = 'GeographicalQueries',
  Jobs = 'Jobs',
  VideoArchiveList = 'VideoArchiveList',
  VehicleDatabase = 'VehicleDatabase',
  MarkedVideo = 'MarkedVideo',
  UserGroups = 'UserGroups',
  DeviceGroups = 'DeviceGroups',
  DeviceGroupNames = 'DeviceGroupNames',
  AllMyDevices = 'AllMyDevices',
}

export const reduxTags = [
  TReduxTag.UserProfile,
  TReduxTag.VideoQueries,
  TReduxTag.RidershipValidations,
  TReduxTag.GeographicalQueries,
  TReduxTag.Jobs,
  TReduxTag.VideoArchiveList,
  TReduxTag.VehicleDatabase,
  TReduxTag.MarkedVideo,
  TReduxTag.UserGroups,
  TReduxTag.DeviceGroups,
  TReduxTag.DeviceGroupNames,
  TReduxTag.AllMyDevices,
];
